import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SakuraText from "../components/sakura-text"

const YearDiv = styled("div")`
  font-weight: 500;
  padding: 0.3em 1em;
  line-height: 1.7em !important;
  border: 2px solid;
  border-radius: 3px;
  color: #fff !important;
  display: inline-block;
  font-size: 21px;
  background-color: #e24291;
  margin-bottom: 30px;
`

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query awardQuery {
      a1: file(relativePath: { eq: "award1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      a2: file(relativePath: { eq: "award15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      a3: file(relativePath: { eq: "award3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      a4: file(relativePath: { eq: "award4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      a5: file(relativePath: { eq: "award5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const padTopCss = css`
    padding-top: 40px;
  `
  return (
    <Layout>
      <SEO
        title="櫻花獎項 - 櫻花家居服務"
        description="櫻花家居服務憑藉優良服務獲得多個獎項，包括2018年香港創業品牌大獎及2017年香港最受歡迎品牌大獎。更於2019年與日本Kalmor 和Eco Shop 簽訂長遠合作協議，發展防霉除味新業務。"
      />
      <div
        css={css`
          max-width: 1080px;
          margin: 0 auto;
          padding: 80px 0;
          align-items: center;
        `}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <SakuraText text="櫻花獎項" size={28} />
            </Col>
          </Row>
          <Row css={padTopCss}>
            <Col md={3} xs={12}>
              <YearDiv>2017年</YearDiv>
            </Col>
            <Col>
              <h2>香港最受歡迎品牌大獎</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Img fluid={data.a1.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>
                成功與日本東芝和Grafton品牌簽訂長遠合作協議， 成為香港獨家代理商
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Img fluid={data.a2.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row css={padTopCss}>
            <Col md={3} xs={12}>
              <YearDiv>2017年</YearDiv>
            </Col>
            <Col>
              <h2>香港健康產業大獎</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Img fluid={data.a3.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row css={padTopCss}>
            <Col md={3} xs={12}>
              <YearDiv>2018年</YearDiv>
            </Col>
            <Col>
              <h2>香港創業品牌大獎</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Img fluid={data.a4.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row css={padTopCss}>
            <Col md={3} xs={12}>
              <YearDiv>2019年</YearDiv>
            </Col>
            <Col>
              <h2>
                與日本KALMOR和ECO SHOP簽訂長遠合作協議，發展除霉除味新業務
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Img fluid={data.a5.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default ThePage
